import React from 'react';

function Google() {
  return (
    <div>
      <h1 style={{ color: "red" }}>Welcome to my Youtube tutorials</h1>
      <p style={{ color: "red" }}>Content coming soon! </p>
    </div>
  );
}

export default Google;
