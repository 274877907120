const skillData = [
    {
      id: 1,
      name: "React",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/react-colored.svg",
      level: "70%"
    },
    {
      id: 2,
      name: "Next.js",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/nextjs-colored-dark.svg",
      level: "50%"
    },
    {
      id: 3,
      name: "TypeScript",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/typescript-colored.svg",
      level: "50%"
    },
    {
      id: 4,
      name: "JavaScript",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/javascript-colored.svg",
      level: "50%"
    },
    {
      id: 5,
      name: "Redux",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/redux-colored.svg",
      level: "30%"
    },
    {
      id: 6,
      name: "TailwindCSS",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/tailwindcss-colored.svg",
      level: "50%"
    },
    {
      id: 7,
      name: "Sass",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/sass-colored.svg",
      level: "60%"
    },
    {
      id: 8,
      name: "Node.js",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/nodejs-colored.svg",
      level: "60%"
    },
    {
      id: 9,
      name: "MongoDB",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/mongodb-colored.svg",
      level: "60%"
    },
    {
      id: 10,
      name: "MySQL",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/mysql-colored.svg",
      level: "40%"
    },
    {
      id: 11,
      name: "HTML5",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/html5-colored.svg",
      level: "70%"
    },
    {
      id: 12,
      name: "jQuery",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/jquery-colored.svg",
      level: "60%"
    },
    {
      id: 13,
      name: "CSS3",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/css3-colored.svg",
      level: "50%"
    },
    {
      id: 14,
      name: "Bootstrap",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/bootstrap-colored.svg",
      level: "40%"
    },
    {
      id: 15,
      name: "Python",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/python-colored.svg",
      level: "20%"
    },
    {
      id: 16,
      name: "Django",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/django-colored-dark.svg",
      level: "30%"
    },
    {
      id: 17,
      name: "PostgreSQL",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/postgresql-colored.svg",
      level: "50%"
    },
    {
      id: 18,
      name: "Supabase",
      link: "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/supabase-colored.svg",
      level: "50%"
    }
  ];
  
  export default skillData;