import React from 'react';

function PageNotFound() {
  return (
    <div>
      <p style={{ color: "red" }}>Error 404: Page cannot be found :( </p>
    </div>
  );
}

export default PageNotFound;
